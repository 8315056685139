import { Address } from '../common/address';
import { BankDetails, LegalEntity } from '../counterparty';
import { LegalEntityCommon } from '../counterparty/workshopCounterparty';
import { Client } from './../common/client'
import Pagination from './../common/pagination'
import { VehicleModification } from './modification'
import { VehicleManufacturer } from './manufacturer';
import { VehicleModel } from './model';

const VehicleSimple = {
  id: true,
  vin: true,
  frameNumber: true,
  year: true,
  color: true,
  plate: true,
  mileage: true,
  manufacturer: VehicleManufacturer,
  model: VehicleModel,
  modification: VehicleModification,
  createdAt: true,
  updatedAt: true
}

const Vehicle = {
  id: true,
  vin: true,
  frameNumber: true,
  year: true,
  color: true,
  plate: true,
  plateHistory: {
    value: true,
    createdAt: true
  },
  mileage: true,
  mileageHistory: {
    value: true,
    createdAt: true
  },
  manufacturer: VehicleManufacturer,
  model: VehicleModel,
  modification: VehicleModification,
  owner: LegalEntity,
  clients: Client,
  createdAt: true,
  updatedAt: true
};

const GlobalVehicle = {
  id: true,
  vin: true,
  frameNumber: true,
  year: true,
  color: true,
  plate: true,
  plateHistory: {
    value: true,
    createdAt: true
  },
  mileage: true,
  mileageHistory: {
    value: true,
    createdAt: true
  },
  manufacturer: VehicleManufacturer,
  model: VehicleModel,
  modification: VehicleModification,
  createdAt: true,
  updatedAt: true
};

const Vehicles = { items: Vehicle, ...Pagination };
const VehiclesSimple = { items: VehicleSimple, ...Pagination };

export {
  Vehicle,
  GlobalVehicle,
  Vehicles,
  VehicleSimple,
  VehiclesSimple
}